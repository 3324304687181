<template>
    <div>
          <div class="pwd">
            <p class="title">添加教师</p>
            <el-input v-model="Moblie" placeholder="请输入教师账号" style="width:300px"></el-input>
             <el-button type="primary" style="margin-left:6px" @click="handleClick">添加</el-button>
        </div>
    </div>
</template>

<script>
import { teacherAdd } from '@/api/user.js'
export default {
  data () {
    return {
      Moblie: ''
    }
  },
  mounted () {

  },
  methods: {
    handleClick () {
      const data = {
        RegisterMobile: this.Moblie
      }
      teacherAdd(data).then(res => {
        // console.log(res)
        if (res.status.code == 1) {
          this.$message({
            message: '添加成功',
            type: 'success'
          })
          this.Moblie = '' // 清空输入框
        } else {
          this.$message.error(res.status.msg)
        }
      }).catch(err => { console.log(err) })
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
